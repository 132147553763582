<template>
  <div class="body" @click="showAccountMenu = showDontSeeAsset = false">
    <div v-if="showActivePopup" class="click-blocker"></div>
    <div v-if="showActivePopup" class="active-asset-popup">
      <div class="title">
        <img src="../assets/question.svg" alt="" />
        <h5>{{ activePopupInfo.activating ? 'Activate Asset' : 'Inactive Asset' }}</h5>
      </div>
      <p v-if="activePopupInfo.activating">
        Click <b>activate</b> to <u>resume</u> receiving renewal notifications for this asset or click <b>cancel</b> to continue <u>not</u> receiving renewal notifications for this asset.
      </p>
      <p v-else>
        Making this asset inactive will <u>not</u> delete it but rather make it less conspicuous and no license renewal information will be forwarded or processed. At any time, you can make it active again by simply
        moving the slider to <u>Active</u>.
      </p>
      <div class="buttons">
        <button @click="showActivePopup = false">Cancel</button>
        <button v-if="activePopupInfo.activating" @click="activateAsset()">Activate</button>
        <button v-else @click="deactivateAsset()">Inactivate</button>
      </div>
    </div>
    <div class="container">
      <div class="title-bar">
        <h1>My Company Assets</h1>
        <div class="account-menu">
          <!-- <button @click="openInNewTab('https://customer.billergenie.com:443/Montana-Corporate/Account/Login/7353')">Pay Invoice</button> -->
          <img src="../assets/menu.svg" style="cursor: pointer" @click.stop="showAccountMenu = !showAccountMenu" alt="" />
          <ul v-show="showAccountMenu">
            <li @click="logout">Logout</li>
          </ul>
        </div>
      </div>
      <p class="error-disclaimer">
        If you notice any errors in the data presented, click <a href="mailto:support@montanacorporate.com">HERE</a> to send an email to Montana Corporate and we'll determine if the error can be rectified and if so, how
        to do it.
      </p>
      <div class="llcs" v-if="!error">
        <h2>LLCS</h2>
        <div class="items-container">
          <div class="item" v-for="llc in llcs" :key="llc.id">
            <div class="filler-div">
              <div class="top-section">
                <img src="../assets/llc-icon.svg" alt="" />
                <span>{{ llc.name }}</span>
              </div>
              <div class="main-section">
                <b>{{ llc.membername }}</b
                ><br />
                {{ llc.address }}<br />
                {{ llc.city }}, {{ llc.state }} {{ llc.zip }}<br />
                {{ formatPhoneNumber(llc.phone) }}<br />
                {{ llc.email }}

                <span class="member" v-for="member in filteredMembers(llc.id)" :key="member.id">
                  <br /><br /><b>{{ member.name }}</b
                  ><br />
                  {{ formatPhoneNumber(member.phone) }}<br />
                  {{ member.email }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="!llcs.length">
            <h3>There are no LLCs for this account.<br /><a href="https://montanacorporate.com/formllcfaq">Create new LLC here.</a></h3>
          </div>
        </div>
      </div>

      <div class="assets" v-if="!error">
        <h2>
          ASSETS
          <span @click.stop="showDontSeeAsset = true"
            >Dont see an asset?
            <div v-if="showDontSeeAsset">
              <p>
                Only asset data that was submitted thru the Vehicle Licensing page of the Montana Corporate website will show here.<br /><br />
                Asset data that was submitted by dealing directly with a Montana Corporate agent for the licensing process will not.<br /><br />
                We keep all your data secure and, if you need it, can send you any information or forward documents about any asset we helped you acquire.
              </p>
            </div>
          </span>
        </h2>
        <div class="items-container">
          <div class="item" v-for="asset in assets" :key="asset.id">
            <div v-if="asset.inactive" class="gray-cover"></div>
            <div class="filler-div">
              <div class="top-section">
                <img :src="getAssetIcon(asset.type)" alt="" />
                <span
                  >{{ asset.year }} {{ asset.make }}<br /><span>{{ asset.model }}</span></span
                >
                <div class="active-asset">
                  <img v-if="!asset.inactive" @click="setActivePopup(asset.id, false)" src="../assets/active.svg" alt="" />
                  <img v-else @click="setActivePopup(asset.id, true)" src="../assets/inactive.svg" alt="" />
                  <p :style="{ right: asset.inactive ? '-0.2rem' : '0.3rem' }">{{ asset.inactive ? 'Inactive' : 'Active' }}</p>
                </div>
              </div>
              <div class="main-section">
                <div class="asset-details">
                  <span>
                    <span style="width: 3.5rem">MILEAGE</span>
                    <span>{{ asset.mileage }}</span>
                  </span>
                  <span>
                    <span>SUBMITTED</span>
                    <span>{{ formatDate(asset.datesubmitted) }}</span>
                  </span>
                  <span>
                    <span style="width: 3.5rem">OWNER</span>
                    <span :title="asset.name">{{ asset.name }}</span>
                  </span>
                  <span>
                    <span>VIN</span>
                    <span>{{ getNumber(asset) }}</span>
                  </span>
                </div>

                <div class="other-details">
                  <h5>OPTIONS</h5>
                  <div class="options">
                    <span v-if="asset.regexpedite == 'RegOnly'">Expedite Registration</span>
                    <span v-if="asset.regexpedite == 'RegAndTitle'">Expedite Registration and Title</span>
                    <span v-if="asset.tempplate">Temp Plate</span>
                    <span v-if="asset.tempplaterush">Temp Plate RUSH</span>
                    <span v-if="asset.overnightdecal">Overnight Decal</span>
                    <span v-if="asset.foreignreg">Foreign Registration</span>
                    <span v-if="asset.breakbond">Break/Bond</span>
                    <span v-if="noOptionsSelected(asset)"> - </span>
                  </div>
                </div>
                <div class="other-details">
                  <h5>HISTORY</h5>
                  <span class="history-items" style="white-space: pre-wrap">{{ asset.timeline ? asset.timeline.replace(/``lb/g, '\n') : ' - ' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!assets.length">
            <h3>There are no assets for this account.<br /><a href="https://montanacorporate.com/licensing">License new asset here.</a></h3>
          </div>
        </div>
      </div>
      <div v-if="error">
        <div class="error">{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  props: ['GLBURL'],
  data() {
    return {
      llcs: [],
      assets: [],
      members: [],
      showAccountMenu: false,
      showDontSeeAsset: false,
      showActivePopup: false,
      activePopupInfo: {
        assetid: null,
        activating: false,
      },
      error: '',
    };
  },
  computed: {
    isShowingPopup() {
      let popupShowing = false;
      this.assets.forEach((asset) => {
        if (asset.showActivePopup) popupShowing = true;
      });
      return popupShowing;
    },
  },
  methods: {
    getUserInfo() {
      fetch(`${this.GLBURL}/getuserinfo`, { credentials: 'include', method: 'POST' })
        .then((res) => {
          if (!res.ok) {
            throw res.status;
          }
          return res.json();
        })
        .then((data) => {
          if (!data.success) throw data.error;
          this.llcs = data.llcs;
          this.assets = data.assets;
          this.members = data.members;
        })
        .catch((error) => {
          console.log(error);
          if (error == '401') {
            this.$router.push({ name: 'login' });
          } else {
            this.error = 'There was an error getting your account information. Please try refreshing the page.';
            console.log('Server Error!');
          }
        });
    },
    setActivePopup(assetid, activating) {
      this.activePopupInfo.assetid = assetid;
      this.activePopupInfo.activating = activating;
      this.showActivePopup = true;
    },
    activateAsset() {
      const index = this.assets.findIndex((asset) => asset.id == this.activePopupInfo.assetid);
      if (index < 0) return;
      this.assets[index].inactive = false;
      this.showActivePopup = false;
      this.sendUpdatedAssetActiveState(false, index);
    },
    deactivateAsset() {
      const index = this.assets.findIndex((asset) => asset.id == this.activePopupInfo.assetid);
      if (index < 0) return;
      this.assets[index].inactive = true;
      this.showActivePopup = false;
      this.sendUpdatedAssetActiveState(true, index);
    },
    sendUpdatedAssetActiveState(inactive, index) {
      fetch(`${this.GLBURL}/updateactiveasset`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ assetid: this.activePopupInfo.assetid, inactive: inactive }),
      })
        .then((res) => {
          if (!res.ok) {
            throw res.status;
          }
          return res.json();
        })
        .then((data) => {
          if (!data.success) throw data.error;
          console.log('set active state successfully');
        })
        .catch((error) => {
          console.log(error);
          if (error == '401') {
            this.$router.push({ name: 'login' });
          } else {
            console.log('did not update active state successfully!');
            this.assets[index].inactive = !inactive;
            alert("There was an error while trying to update your asset's active state.");
          }
        });
    },
    formatPhoneNumber(phoneNumberString) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return phoneNumberString;
    },
    filteredMembers(llcid) {
      let filteredMembers = [];
      this.members.filter((member) => {
        if (member.llcid == llcid) {
          filteredMembers.push(member);
        }
      });
      filteredMembers = filteredMembers.sort((a, b) => {
        if (a.membernumber > b.membernumber) return 1;
        else if (a.membernumber < b.membernumber) return -1;
        else return 0;
      });
      return filteredMembers;
    },
    getAssetIcon(assetType) {
      let img = '';

      if (assetType == 'carTruck') {
        img = 'car';
      } else if (assetType == 'boat') {
        img = 'boat';
      } else if (assetType == 'plane') {
        img = 'plane';
      } else if (assetType == 'motorhome') {
        img = 'motorhome';
      } else if (assetType == 'atv' || assetType == 'motorcycle') {
        img = 'atv';
      } else if (assetType == 'trailer') {
        img = 'trailer';
      } else {
        img = 'car';
      }

      var images = require.context('../assets/', false, /\.svg$/);
      return images('./' + img + '.svg');
    },
    getNumber(asset) {
      if (asset.vin) {
        return '*' + asset.vin;
      } else if (asset.hid) {
        return '*' + asset.hid;
      } else if (asset.nnumber) {
        return asset.nnumber;
      }
    },
    noOptionsSelected(asset) {
      if (!asset.regexpedite || !asset.tempplate || !asset.tempplaterush || !asset.overnightdecal || !asset.foreignreg || !asset.breakbond) {
        return true;
      } else {
        return false;
      }
    },
    formatDate(rawDate) {
      let d = new Date(rawDate);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
    },
    logout() {
      fetch(`${this.GLBURL}/logout`, { credentials: 'include', method: 'POST' })
        .then((res) => {
          if (!res.ok) {
            throw res.status;
          }
          return res.json();
        })
        .then((data) => {
          if (!data.success) throw data.error;
          this.$router.push({ name: 'login' });
        })
        .catch((error) => {
          console.log(error);
          if (error == '401') {
            this.$router.push({ name: 'login' });
          } else {
            this.error = 'There was an error logging out. Please try again.';
            console.log('Server Error!');
          }
        });
    },
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 100px);
  .container {
    width: 100%;
    max-width: 75rem;
    padding: 2rem 3rem;

    h2 {
      font-size: 1.3rem;

      span {
        color: #45a5fe;
        font-size: 1rem;
        font-weight: normal;
        padding-left: 1rem;
        cursor: pointer;
        position: relative;

        &:hover {
          text-decoration: underline;
        }

        div {
          z-index: 5;
          max-width: 30rem;
          width: calc(95vw - 9rem);
          position: absolute;
          top: 2rem;
          left: -3rem;
          color: black;
          padding: 2rem;
          background: white;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          p {
            margin: 0;
          }
        }
      }
    }

    .assets,
    .llcs {
      margin-top: 3rem;
    }
  }
}

@media only screen and (max-width: 520px) {
  .body {
    min-height: calc(100vh + 100px);
    .container {
      padding: 1rem 1.5rem;
    }
  }
}

h3 {
  font-weight: 400;
}

.title-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    width: 3rem;
    margin-right: 0;
  }
}

.account-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;

  img {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  button {
    border: none;
    border-radius: 4rem;
    color: white;
    background: #464765;
    height: 3rem;
    padding: 0 2.5rem;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 0.5rem;
  }

  ul {
    list-style: none;
    position: absolute;
    top: 2.5rem;
    background: #ebebeb;
    border-radius: 0.3rem;
    right: 0;
    z-index: 100;
    text-decoration: none;
    padding: 0;
    li {
      border-radius: 0.3rem;
      padding: 0.5rem 1.5rem;
      text-align: center;
      cursor: pointer;
      &:hover {
        background: #ccc;
      }
    }
  }
}

.error-disclaimer {
  max-width: 50rem;

  a {
    text-decoration: none;
    color: black;
    font-weight: 500;
  }
}

.asset-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  & > span {
    width: 50%;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3rem;

    & > span:first-child {
      font-weight: 600;
      font-size: 0.7rem;
      width: 4.5rem;
    }
    & > span:last-child {
      flex: 1;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.other-details {
  margin-bottom: 1rem;
  h5 {
    font-size: 0.8rem;
    margin: 0;
    margin-bottom: 0.2rem;
    font-weight: 600;
  }
  .history-items {
    font-weight: 500;
  }
  .options {
    span {
      display: block;
    }
  }
}

.items-container {
  columns: 4 23rem;
  gap: 3rem;
}
.item {
  background: radial-gradient(50% 50% at 50% 50%, #f9f9f9 0%, #f4f4f4 100%);
  padding: 1.5rem 2rem;
  max-width: 30rem;
  margin-bottom: 3rem;
  box-sizing: border-box;
  position: relative;

  .gray-cover {
    background: rgb(240, 240, 240, 76%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .filler-div {
    display: inline-block;

    .top-section {
      display: flex;
      flex-direction: row;
      align-items: center;

      .active-asset {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 2;

        img {
          cursor: pointer;
        }

        & > p {
          position: absolute;
          top: 0.8rem;
          font-size: 0.8rem;
        }
      }

      img {
        width: 2.5rem;
      }
      & > span {
        margin-left: 0.7rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #303030;
        line-height: 1;

        span {
          font-size: 0.95rem;
          font-weight: 600;
        }
      }
    }

    .main-section {
      font-size: 0.8rem;
      margin: 1rem 0.6rem;
      display: inline-block;
    }
  }
}
.error {
  margin: 2rem;
  font-size: 1.5rem;
  color: red;
}

.click-blocker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: transparent;
}

.active-asset-popup {
  z-index: 4;
  position: fixed;
  height: 13rem;
  width: 25rem;
  max-width: 75vw;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: white;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: space-around;

  @media only screen and (max-width: 456px) {
    height: 16rem;
  }

  img {
    width: 2.5rem;
  }

  .title {
    display: flex;

    h5 {
      font-size: 1.6rem;
      font-weight: normal;
      padding-left: 1rem;
      margin: 0;
      margin-top: 0.4rem;
    }
  }

  p {
    padding-left: 3.5rem;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0;

    button {
      margin-left: 0.5rem;
      padding: 0.6rem 1rem;
      border: none;
      text-transform: uppercase;
      border-radius: 0.25rem;
      color: white;
      cursor: pointer;

      &:first-child {
        background: #aba9a9;
      }

      &:last-child {
        background: #0065ae;
      }
    }
  }
}
</style>
